<template>
	<el-dialog title="文件上传" :close-on-click-modal="false" :visible.sync="visible" width="1000px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="12">
					<el-upload ref="uploadScanFile" class="upload-demo" action="action" :http-request="uploadScanFile" drag
						multiple :show-file-list=false :auto-upload="true">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					</el-upload>
				</el-col>
				<el-col :span="11" style="border:solid #dadada 1px">
					<el-form-item label="文件类型" prop="fileKind" class="item">
						<el-select class="selItemInput" v-model="dataForm.fileKind" placeholder="请选择" :disabled="true">
							<el-option v-for="item in documentTypeList" :key="item.displayValue" :label="item.displayName"
								:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-table :data="dataForm.records" style="width: 100%" :cell-style="{ padding: '0px' }" border>
						<el-table-column type="index" label="序号" width="80">
						</el-table-column>
						<el-table-column label="文件名称" width="300">
							<template slot-scope="scope">
								<el-input class="sp-input" v-model="scope.row.fileName" placeholder="文件名称"></el-input>
							</template>
						</el-table-column>
						<el-table-column label="报告时间" width="190">
							<template slot-scope="scope">
								<el-date-picker v-model="scope.row.createTime" type="date"
									value-format="yyyy-MM-dd" placeholder="选择日期">
								</el-date-picker>
							</template>
						</el-table-column>
						<el-table-column label="报告人" width="200">
							<template slot-scope="scope">
								<el-input v-model="scope.row.createMan" placeholder="报告人"></el-input>
							</template>
						</el-table-column>
						<el-table-column label="操作">
							<template slot-scope="scope">
								<el-button v-preventReClick type="success" size="small"
									@click="downloadFile(scope.row.fileUri, scope.row.fileName)">查看</el-button>
								<el-button v-preventReClick type="primary" size="small"
									@click="delFile(scope.$index)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
			<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "workfile-add-batch",
	data() {
		return {
			visible: false,
			inline: false,
			documentTypeList: [],
			action: "",
			dataForm: {
				subjectNo: '',
				workNo: '',
				fileKind: '',
				records: []
			},
			dataRule: {
				fileKind: [
					{ required: true, message: "请选择文件类型", trigger: "change" }
				]
			},
		}
	},
	methods: {
		init(fileKind) {
			// this.$nextTick(() => {
			// 	this.$refs["dataForm"].resetFields();
			// })
			this.visible = true;
			this.dataForm = {
				subjectNo: $common.getItem("subjectNo"),
				workNo: $common.getItem("workNo"),
				fileKind: fileKind,
				records: []
			};
			this.getDocumentTypeList();
		},
		//审查文件类型
		getDocumentTypeList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getWorkFileType/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.documentTypeList = data.body;
				}
			});
		},
		uploadScanFile(param) {
			if (!this.dataForm.fileKind) {
				this.$message.error('请选择文件类型之后再进行文件上传');
			} else {
				const formData = new FormData();
				let upData = []

				for (let i = 0; i < this.$refs.uploadScanFile.uploadFiles.length; i++) {
					upData.push(this.$refs.uploadScanFile.uploadFiles[i].raw);
					formData.append('files', this.$refs.uploadScanFile.uploadFiles[i].raw);
				}
				this.$refs.uploadScanFile.clearFiles();
				this.$http.post(
					this.$store.state.httpUrl + "/file/uploadMultipleFile/" + $common.getItem("workNo") + "/20/" + this.dataForm.fileKind,
					formData,
					{
						headers: { 'Content-Type': 'multipart/form-data' }
					},
				).then(res => {
					res = res.data;
					if (res.resultCode === 200) {
						res.body[0].filePath = res.body[0].fileSavePath
						res.body[0].fileName = this.extractFileName(res.body[0].fileName)
						this.dataForm.records.push(res.body[0])
					} else {
						this.$message.info('文件上传失败')
					}
				})
			}
		},
		extractFileName(fileName) {
			let index = fileName.lastIndexOf(".");
			return fileName.substr(0, index)
		},
		downloadFile(url, filename) {
            filename = this.getSuffix(url, filename)
            this.getBlob(url, (blob) => {
                this.saveAs(blob, filename)
            })
        },
        getSuffix(url, filename) {
            let lastIndex = url.lastIndexOf(".");
            return filename + url.substr(lastIndex)
        },
        getBlob(url, cb) {
            var xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = function () {
                if (xhr.status === 200) {
                    cb(xhr.response)
                }
            }
            xhr.send()
        },
        saveAs(blob, filename) {
            if (window.navigator.msSaveOrOpenBlob) {
                navigator.msSaveBlob(blob, filename)
            }
            else {
                var link = document.createElement('a')
                var body = document.querySelector('body')

                link.href = window.URL.createObjectURL(blob)
                link.download = filename

                link.style.display = 'none'
                body.appendChild(link)

                link.click()
                body.removeChild(link)
                window.URL.revokeObjectURL(link.href)
            }
        },
		delFile(index) {
			this.dataForm.records.splice(index, 1)
		},
		dataFormSubmit() {
			if (this.dataForm.records == undefined || this.dataForm.records.length <= 0) {
                this.$message.error('请上传文件！');
                return;
            }
            for(var i = 0; i < this.dataForm.records.length; i++) {
                if(this.dataForm.records[i].fileName == '' || this.dataForm.records[i].fileName == undefined) {
                    this.$message.error('请填写文件名称！');
                    return;
                }
            }
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/projectworkfileinfo/batchSave";
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData({
							recId: this.dataForm.recId,
							workNo: $common.getItem("workNo"),
							subjectNo: $common.getItem("subjectNo"),
							fileKind: this.dataForm.fileKind,
							records: this.dataForm.records
						})
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}

	}
}
</script>
<style scoped>
.upload-demo /deep/ .el-upload-dragger {
	width: 260px;
	height: 70px;
}

.upload-demo /deep/ .el-upload-dragger .el-icon-upload {
	font-size: 40px;
	margin: 0 0;
	line-height: 40px;
}

.upload-demo /deep/ .el-upload-dragger .el-upload__text {
	line-height: 20px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.sp-input /deep/ .el-input__inner {
	width: 100% !important;
}
</style>
