import { render, staticRenderFns } from "./workfile.vue?vue&type=template&id=099d4c63&scoped=true&"
import script from "./workfile.vue?vue&type=script&lang=js&"
export * from "./workfile.vue?vue&type=script&lang=js&"
import style0 from "./workfile.vue?vue&type=style&index=0&id=099d4c63&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "099d4c63",
  null
  
)

export default component.exports