<template>
	<el-dialog title="文件修改" :close-on-click-modal="false" :visible.sync="visible" width="550px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true" class="border-form">
			<el-row class="set_border">
				<!-- <el-col :span="12">
					<el-form-item label="文件类型" prop="fileKind">
						<el-select class="selItemInput" v-model="dataForm.fileKind" placeholder="请选择">
							<el-option
									v-for="item in documentTypeList"
									:key="item.displayValue"
									:label="item.displayName"
									:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col> -->
				<el-col :span="24">
					<el-form-item label="文件名称" prop="fileName" class="item">
						<el-input class="sp-input" v-model="dataForm.fileName" placeholder="文件名称" style="width:300px">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="报告人" prop="createMan" class="item">
						<el-input class="sp-input" v-model="dataForm.createMan" placeholder="报告人" style="width:300px">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="报告时间" prop="createTime" class="item">
						<el-date-picker style="width: 300px;" v-model="dataForm.createTime" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="监理文件" class="item">
						<el-upload style="margin-top: 5px;margin-bottom: -8px;" v-if="!dataForm.filePath" ref="uploadScanFile" class="upload-demo" action="action" drag
							:http-request="uploadScanFile" :show-file-list=false :auto-upload="true">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						</el-upload>
						<div v-if="dataForm.filePath">
							<el-button v-preventReClick type="success" size="small"
								@click="downloadFile(dataForm.filePathUrl, dataForm.fileName)">查看</el-button>
							<el-button v-preventReClick type="primary" size="small" @click="delFile()">删除</el-button>
						</div>

					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
			<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>

		</el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "workfile-add-or-update",
	data() {
		return {
			visible: false,
			inline: false,
			documentTypeList: [],
			action: "",
			dataForm: {
				recId: '',
				subjectNo: '',
				workNo: '',
				fileKind: '',
				fileName: '',
				filePath: '',
				createMan: '',
				createTime: '',
			},
			dataRule: {
				// fileKind: [
				// 	{ required: true, message: "请选择文件类型", trigger: "change" }
				// ],
				fileName: [
					{ required: true, message: "请输入文件名称", trigger: "blur" }
				],
				createMan: [
					{ required: true, message: "请输入报告人", trigger: "blur" }
				],
				createTime: [
					{ required: true, message: "请输入报告时间", trigger: "blur" }
				]
			},
		}
	},
	methods: {
		init(id) {
			this.visible = true;
			this.dataForm = {
				recId: '',
				subjectNo: $common.getItem("subjectNo"),
				workNo: $common.getItem("workNo"),
				fileKind: '',
				fileName: '',
				filePath: '',
				createMan: '',
				createTime: '',
			};
			this.dataForm.recId = id ? id : 0;
			this.getDocumentTypeList();
			this.$nextTick(() => {
				this.$refs["dataForm"].resetFields();
				if (this.dataForm.recId) {
					this.$http({
						url: this.$store.state.httpUrl + `/business/projectworkfileinfo/info/${this.dataForm.recId}`,
						method: 'get'
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.dataForm = data.body;
						}
					})
				}
			})
		},
		//审查文件类型
		getDocumentTypeList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getWorkFileType/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.documentTypeList = data.body;
				}
			});
		},
		uploadScanFile(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadScanFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/20/" + this.dataForm.fileKind,
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data;
				if (res.resultCode === 200) {
					this.dataForm.filePath = res.body.fileSavePath;
					this.dataForm.fileName = this.extractFileName(res.body.fileName);
					this.dataForm.filePathUrl = res.body.fileUri;
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		extractFileName(fileName) {
			let index = fileName.lastIndexOf(".");
			return fileName.substr(0, index)
		},
		downloadFile(fileUri, filename) {
			filename = this.getSuffix(fileUri, filename)
			this.getBlob(fileUri, (blob) => {
				this.saveAs(blob, filename)
			})
		},
		getSuffix(url, filename) {
			let lastIndex = url.lastIndexOf(".");
			return filename + url.substr(lastIndex)
		},
		getBlob(url, cb) {
			var xhr = new XMLHttpRequest()
			xhr.open('GET', url, true)
			xhr.responseType = 'blob'
			xhr.onload = function () {
				if (xhr.status === 200) {
					cb(xhr.response)
				}
			}
			xhr.send()
		},
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename)
			}
			else {
				var link = document.createElement('a')
				var body = document.querySelector('body')

				link.href = window.URL.createObjectURL(blob)
				link.download = filename

				link.style.display = 'none'
				body.appendChild(link)

				link.click()
				body.removeChild(link)
				window.URL.revokeObjectURL(link.href)
			}
		},
		delFile() {
			this.dataForm.filePath = "";
			// this.dataForm.fileName = "";
			this.dataForm.filePathUrl = "";
			// this.dataForm.createMan = "";
			// this.dataForm.createTime = "";
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					const _url = "/business/projectworkfileinfo/update";
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData({
							recId: this.dataForm.recId,
							workNo: $common.getItem("workNo"),
							subjectNo: $common.getItem("subjectNo"),
							fileName: this.dataForm.fileName,
							fileKind: this.dataForm.fileKind,
							filePath: this.dataForm.filePath,
							createMan: this.dataForm.createMan,
							createTime: this.dataForm.createTime
						})
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
}
</script>
<style scoped>
.addFileBox {
	display: inline-block;
	margin-right: 10px;
}

.upload-demo /deep/ .el-upload-dragger {
	width: 260px;
	height: 70px;
}

.upload-demo /deep/ .el-upload-dragger .el-icon-upload {
	font-size: 40px;
	margin: 0 0;
	line-height: 40px;
}

.upload-demo /deep/ .el-upload-dragger .el-upload__text {
	line-height: 20px;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

.border-form .el-row.set_border .col-set_border {
	border-right: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.sp-input /deep/ .el-input__inner {
	width: 100% !important;
}
</style>
