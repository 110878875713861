<template>
	<!-- 巡视信息列表 -->
	<div class="mod-config">
		<div>
			<el-card style="margin-top: -5px; height: 673px;" body-style="height: 100%; padding: 1;">
				<div class="title">监理文件</div>
				<div class="line" style="margin-bottom:0px"></div>
				<el-container style="height: 100%;">
					<el-aside width="200px" style="border-right: 1px solid #eee">
						<div class="tree-container treeText" v-if="fileKindList != null">
							<el-tree :data="fileKindList" node-key="recId" default-expand-all :expand-on-click-node="false"
								highlight-current :props="defaultProps" @node-click="clickNode"
								style="background-color: white;">
								<span class="custom-tree-node treeText" slot-scope="{ node, data }">
									<el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
										<span>{{ node.label }}</span>
									</el-tooltip>
								</span>
							</el-tree>
						</div>
					</el-aside>
					<el-main style="padding: 0 10px;border-left: 1px solid #eee">
						<el-row
							style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 56px;width: 101%;">
							<el-col :span="9" style="margin-left:20px;margin-top: 18px">
								<el-button class="btn" type="primary" size="small" :disabled="fileKind == ''"
									@click="addOrUpdateBatchHandle()">上传</el-button>
								<el-button v-preventReClick class="btn" type="primary" size="small"
									:disabled="disabledBatchDelete" v-if="showDelete"
									@click="deleteBatchHandle()">删除</el-button>
								<el-button v-preventReClick class="btn" type="primary" size="small"
									:disabled="dataList.length == 0" @click="batchUpdateShowSortHandle()">排序保存</el-button>
							</el-col>
							<el-col :span="10" style="margin-top: 12px">
								<el-form ref="form" :model="form" inline label-width="90px" label-position="left">
									<el-form-item label="文件名称" class="selFormItem">
										<el-input class="selSearchInput" placeholder="文件名称" v-model="fileName"></el-input>
									</el-form-item>
								</el-form>
							</el-col>
							<el-col :span="4" style="margin-top:18px">
								<el-button v-preventReClick class="btn" type="primary" size="small"
									@click="selGetDataList()" v-if="showSearch">查询</el-button>
								<el-button v-preventReClick class="rebtn" size="small"
									@click="resetFormData()">重置</el-button>
							</el-col>
						</el-row>
						<el-card style="margin-top: 0px;margin-left:-12px;height: 583px;overflow: unset"
							body-style="height:560px;padding: 0;">
							<el-row style="margin-bottom: 10px;">
								<el-col :span="24" style="height: 100%;">
									<div class="box" style="margin-top:-23px">
										<el-table height="520" :row-class-name="tableRowClassName" border
											:row-style="selectRowBackground" ref="dataListTable" class="dataListTable"
											:data="dataList" header-align="center" style="width: 100%; margin-bottom: 20px;"
											@selection-change="handleSelectionChange"
											header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }">
											<el-table-column header-align="center" align="center" width="60"
												type="selection" label=" "></el-table-column>
											<el-table-column type="index" label="NO." width="60" align="center">
												<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index +
													1
												}}</template>
											</el-table-column>
											<!-- <el-table-column label="文件类型" width="100" align="center">
								<template slot-scope="scope">
									<span>{{ getDocumentType(scope.row.fileKind) }}</span>
								</template>
							</el-table-column> -->
											<el-table-column prop="fileName" label="文件名称" header-align="center" align="left"
												show-overflow-tooltip>
												<template slot-scope="scope">
													<el-button v-preventReClick type="text" size="small"
														@click="viewPdf(scope.row.filePath, scope.row.filePathUrl, scope.row.fileName)">{{
															scope.row.fileName }}</el-button>
												</template>
											</el-table-column>
											<el-table-column prop="createTime" label="报告时间" width="120" align="center">
											</el-table-column>
											<el-table-column prop="createMan" label="报告人" width="80" align="center">
											</el-table-column>
											<el-table-column label="序号" width="80" align="center">
												<template slot-scope="scope">
													<el-input v-model="scope.row.showSort" class="sp-input"></el-input>
												</template>
											</el-table-column>
											<el-table-column label="操作" width="155px"
												v-if="showDelete || showCheck || showEdit || showFileDownload" align="left">
												<template slot-scope="scope">
													<!--							  <el-button v-preventReClick type="primary" size="mini"@click="auditeHandle(scope.row.recId)" v-if="showCheck">归档</el-button>-->
													<el-button v-preventReClick type="text" size="mini"
														@click="selectFile(scope.row.filePathUrl)"
														v-if="showCheck && scope.row.filePathUrl">查看</el-button>
													<el-button v-preventReClick type="text" size="mini"
														@click="addOrUpdateHandle(scope.row.recId)"
														v-if="showEdit">修改</el-button>
													<el-button v-preventReClick type="text" size="mini"
														@click="downLoadFile(scope.row.filePathUrl, scope.row.fileName)"
														v-if="showFileDownload && scope.row.filePathUrl">下载</el-button>
													<el-button v-preventReClick type="text" size="mini"
														@click="deleteHandle(scope.row.recId)"
														v-if="showDelete">删除</el-button>
												</template>
											</el-table-column>
										</el-table>
									</div>
									<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
										@current-change="currentChangeHandle" :current-page="pageIndex"
										:page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
										layout="total, sizes, prev, pager, next, jumper">
									</el-pagination>
								</el-col>
							</el-row>
						</el-card>
					</el-main>
				</el-container>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
		<add-or-update-batch v-if="addOrUpdateBatchVisible" ref="addOrUpdateBatch"
			@refreshDataList="getDataList()"></add-or-update-batch>
		<file-type-seting v-if="fileTypeSetingVisible" ref="fileTypeSeting"
			@refreshDataList="getDocumentTypeList()"></file-type-seting>
		<view-pdf v-if="viewPdfVisible" ref="viewPdf" @refreshDataList="dataList"></view-pdf>
	</div>
</template>
<script>
import AddOrUpdate from './workfile-add-or-update'
import AddOrUpdateBatch from './workfile-add-batch'
import FileTypeSeting from './file-type-seting'
import $common from "@/utils/common.js"
import ViewPdf from "../projectInfo/viewPdf.vue"
export default {
	data() {
		return {
			viewPdfVisible: false,
			showSearch: false,
			showAdd: false,
			showFileKindSet: false,
			showFileDownload: false,
			showCheck: false,
			showEdit: false,
			showArchive: false,
			showDelete: false,
			pageSpan: 12,
			tabIndex: '',
			fileTypeSetingVisible: false,
			addOrUpdateVisible: false,
			addOrUpdateBatchVisible: false,
			auditVisible: false,
			replyVisible: false,
			disabledBatchDelete: true,
			recNo: '',
			fileKindList: [],
			form: {},
			returnStatus: '',
			examineStatus: '',
			examineCompany: '',
			fileKind: '',
			fileName: '',
			fileType: '',
			examineTime: [],
			dataList: [],
			tableSelVal: [],
			tableSelIdArray: [],
			selAllFlg: false,
			closeTime: [],
			//审查文件类型
			documentTypeList: [],
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
			options: [
				{
					value: "0",
					label: "未审查"
				},
				{
					value: "1",
					label: "审查"
				}
			],
			option: [
				{
					value: "0",
					label: "未返回"
				},
				{
					value: "1",
					label: "返回"
				}
			],
			defaultProps: {
				children: 'childList',
				label: 'kindName'
			}
		};
	},
	components: {
		AddOrUpdate,
		AddOrUpdateBatch,
		FileTypeSeting,
		ViewPdf
	},
	mounted() {
		this.showSearch = $common.isAuth('workfile:search');
		this.showFileKindSet = $common.isAuth('workfile:file:kind:set');
		this.showAdd = $common.isAuth('workfile:add');
		this.showEdit = $common.isAuth('workfile:edit');
		this.showDelete = $common.isAuth('workfile:delete');
		this.showCheck = $common.isAuth('workfile:check');
		this.showArchive = $common.isAuth('workfile:archive');
		this.showFileDownload = $common.isAuth('workfile:download');
		if (!this.showFileKindSet && !this.showAdd) {
			this.pageSpan = 24;
		}
		this.getDocumentTypeList();
		this.getDataList();
		this.getFileKindList();
	},
	methods: {
		selectFile(url) {
			window.open(url);
		},
		viewPdf(filePath, fileUri, filename) {
			// pdf和doc文件，需要pdf在线预览，其他类型文件直接下载
			let suffixType = fileUri.substr(fileUri.lastIndexOf(".") + 1)
			if(suffixType != '' && (suffixType === 'pdf' || suffixType === 'doc' || suffixType === 'docx')) {
				// $common.viewFile(filePath)
				this.viewPdfVisible = true;
				this.$nextTick(() => {
					this.$refs.viewPdf.init(filename, filePath)
				})
			} else {
				filename = this.getSuffix(fileUri, filename)
				this.getBlob(fileUri, (blob) => {
					this.saveAs(blob, filename)
				})
			}
		},
		downLoadFile(fileUri, filename) {
			filename = this.getSuffix(fileUri, filename)
			this.getBlob(fileUri, (blob) => {
				this.saveAs(blob, filename)
			})
		},
		getSuffix(url, filename) {
			let lastIndex = url.lastIndexOf(".");
			return filename + url.substr(lastIndex)
		},
		getBlob(url, cb) {
			var xhr = new XMLHttpRequest()
			xhr.open('GET', url, true)
			xhr.responseType = 'blob'
			xhr.onload = function () {
				if (xhr.status === 200) {
					cb(xhr.response)
				}
			}
			xhr.send()
		},
		saveAs(blob, filename) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveBlob(blob, filename)
			}
			else {
				var link = document.createElement('a')
				var body = document.querySelector('body')

				link.href = window.URL.createObjectURL(blob)
				link.download = filename

				link.style.display = 'none'
				body.appendChild(link)

				link.click()
				body.removeChild(link)
				window.URL.revokeObjectURL(link.href)
			}
		},
		getDocumentType(type) {
			let rtn = "";
			this.documentTypeList.forEach(function (item) {
				if (item.displayValue === type) {
					rtn = item.displayName;
				}
			});
			return rtn;
		},
		getFileKindList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/filekindconfig/getTreeList",
				method: "get",
				params: {
					workNo: $common.getItem("workNo"),
					kindType: "02",
					limit: 999,
					page: 1
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.fileKindList = data.body;
				}
			});
		},
		//获取数据
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectworkfileinfo/list",
					method: "get",
					params: {
						workNo: $common.getItem("workNo"),
						subjectNo: $common.getItem("subjectNo"),
						fileKind: this.fileKind,
						fileName: this.fileName,
						limit: this.pageSize,
						page: this.pageIndex,
						type: 1
					},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
						this.pageIndex2 = this.pageIndex
					}
				});
			}
		},
		archiveFile(id) {
			this.$confirm(`确定对选中的数据进行归档操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectworkfileinfo/archiveFile/" + id,
					method: "get",
					data: this.$http.adornData({
						recNo: id
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			});
		},
		removeArchiveFile(id) {
			this.$confirm(`确定对选中的数据进行取消归档操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/projectworkfileinfo/removeArchiveFile/" + id,
					method: "get",
					data: this.$http.adornData({
						recNo: id
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			});
		},
		fileTypeSetingHandle() {
			this.fileTypeSetingVisible = true
			this.$nextTick(() => {
				this.$refs.fileTypeSeting.init('3')
			})
		},
		clickNode(data) {
			this.fileKind = data.recId
			this.pageIndex = 1
			this.getDataList()
		},
		//审查文件类型
		getDocumentTypeList() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getWorkFileType/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.documentTypeList = data.body;
				}
			});
		},
		//删除
		deleteHandle(id) {
			this.recNo = id ? id : 0;
			this.$confirm(`确定对选中的数据进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + `/business/projectworkfileinfo/delete/${this.recNo}`,
					method: "POST"
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				});
			})
				.catch(() => { })
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 170;
			return { height: height + 'px' }
		},
		// 审核
		auditeHandle(id) {
			// TODO
		},
		handleSelectionChange(val) {
			this.tableSelVal = val;
			if (val.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		// 当前页
		currentChangeHandle(val) {
			if (val) {
				this.pageIndex = val
			} else {
				this.pageIndex = 1
			}
			this.getDataList()
		},
		tableRowClassName({ row, rowIndex }) {
			let color = ''
			for (let item of this.tableSelVal) {
				if (item.id === row.id) color = 'table-SelectedRow-bgcolor'
			}
			return color
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		addOrUpdateBatchHandle() {
			this.addOrUpdateBatchVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdateBatch.init(this.fileKind)
			})
		},
		deleteBatchHandle() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let recIds = this.tableSelVal.map((item) => item.recId);
				this.$http({
					url: this.$store.state.httpUrl + `/business/projectworkfileinfo/deleteBatch`,
					method: 'post',
					data: this.$http.adornData({
						records: recIds
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			})
				.catch(() => { })
		},
		batchUpdateShowSortHandle() {
			if (this.dataList.length > 0) {
				this.$http({
					url: this.$store.state.httpUrl + `/business/projectworkfileinfo/batchUpdateShowSort`,
					method: 'post',
					data: this.$http.adornData({
						records: this.dataList
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				});
			}
		},
		setCategory(value) {
			this.fileType = value;
			this.pageIndex = 1;
			this.getDataList();
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		toggleAllSelection() {
			this.$refs.dataListTable.toggleAllSelection();
		},
		resetFormData() {
			this.fileName = "";
			this.fileKind = "";
			this.getDataList();
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.tableSelVal.map((item) => item.recId);
			if (checkIdList.includes(row.recId)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},

	}
};
</script>
<style scoped="scoped">
.tree-container {
	background-color: white;
}

.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

.treeText {
	white-space: nowrap;
	/*设置不换行*/
	overflow: hidden;
	/*设置隐藏*/
	text-overflow: ellipsis;
	/*设置隐藏部分为省略号*/
}

.dataListTable {
	margin-top: 5px;
}

::v-deep(.el-table th.el-table__cell) {
	/* background-color: #d3e2f4 !important; */
	font-size: 15px;
	padding: 2px 0;
}

.my-pagination {
	margin-right: 20px !important;
}


::v-deep(.selSearchInput .el-input__inner) {
	width: 150px !important;
	height: 30px !important;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}

.sp-input /deep/ .el-input__inner {
	width: 100% !important;
}
</style>
